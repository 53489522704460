import { policyList } from "@/enums/permissions";
import { PusherNotification } from "@/interfaces/notification";
import { Vendor, VendorDocuments, vendorModel } from "@/interfaces/vendor";
import { pusherEvents } from "@/internal";
import { store } from "@/internal";
import { vendorForm } from "@/metadata/vendor";
import { i18n } from "@/plugins/i18n";
import { messagesService } from "@/services/messages.service";
import { vendorService } from "@/services/vendor.service";
import { Callback, PageNavAction } from "@/types/types";
import {
  BooleanCheck,
  DynamicFormComponent,
  TableAction,
  TableComponent,
  TableFieldType,
  TableHeader,
  TablePagination,
  TableSuccessModalResponse
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./vendorForm.template.vue";

const namespace = "VendorModule";
@Component({
  mixins: [Template],
  components: {
    DynamicFormComponent,
    TableComponent
  },
  inject: ["$changes"]
})
export default class VendorFormComponent extends Vue {
  public formFields = vendorForm;
  public pendingFiles: File[] = [];
  public deletedFiles: File[] = [];
  public isLoading = false;
  public invalidFileName: boolean = false;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  public hasModifyPermission: boolean = false;
  public paginate = vendorService.paginationAction();
  public tabsModel = {
    notes: [
      {
        name: "new vendor note",
        title: "title",
        note: ""
      }
    ],
    contacts: [
      {
        name: "",
        phone: "",
        email: ""
      }
    ]
  };
  public model = vendorModel;

  public fileOptions = {
    multiple: true,
    accept: "image/*"
  };

  public fileHeaders: TableHeader[] = [
    {
      label: "File Name",
      value: "attachment_file_name",
      class: "tdt__headers__fieldLong",
      fieldComponent: TableFieldType.string
    },
    {
      label: "Date",
      value: "created_at",
      class: "tdt__headers__fieldLong",
      fieldComponent: TableFieldType.fnsDate,
      sortable: true
    },
    {
      label: "Uploaded by",
      value: "user",
      class: "tdt__headers__fieldLong",
      fieldComponent: TableFieldType.string
    }
  ];

  public fileActions: TableAction[] = [
    {
      icon: "fas fa-eye",
      action: (...arg: any[]) => {
        const mediumUrl = arg[0].attachment_url;
        window.open(mediumUrl, "_blank");
      }
    },
    {
      icon: "fal fa-trash-alt",
      modalActions: {
        modalNumber: 1,
        modalQuestion: i18n.t("vendors.attachment_delete_confirm").toString(),
        modalSuccessText: "yes",
        modalSuccessAction: (arg: TableSuccessModalResponse) => {
          arg.item._destroy = true;
          this.deletedFiles.push(arg.item.id);
          arg.unselectModal();
        },
        modalCancelText: "no"
      }
    }
  ];
  public tableColors: string[] = [
    "#ffffff",
    "#f2f2f2",
    "#f2f2f2",
    "#ffffff",
    "#ffffff"
  ];
  public activeTab = 0;
  @Getter("vendorToEdit", { namespace })
  public vendorToEdit!: Vendor | null;
  @Getter("vendorFiles", { namespace })
  public vendorFiles!: VendorDocuments[];
  @Getter("loading", { namespace })
  public loading!: boolean;
  @Getter("loadingFiles", { namespace })
  public loadingFiles!: boolean;
  @Getter("pagination", { namespace })
  public pagination!: TablePagination;
  @Action("addVendor", { namespace })
  protected addVendor!: Callback;
  @Action("updateVendor", { namespace })
  protected updateVendor!: Callback;
  @Action("getVendor", { namespace })
  protected getVendor!: Callback;
  @Action("loadVendorFiles", { namespace })
  protected loadVendorFiles!: Callback;
  @Action("clearVendorToEdit", { namespace })
  protected clearVendorToEdit!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  protected setPageNav!: PageNavAction;
  @Prop({ default: false })
  protected edit!: boolean;

  public cancel() {
    this.clearVendorToEdit();
    this.$router.push({ name: "vendor-list" });
  }

  public async save() {
    const form = this.$refs.vendorForm as DynamicFormComponent;
    const formResult = await form.submit();
    if (formResult.valid) {
      let modelCopy = cloneDeep(formResult.currentModel)!;
      modelCopy = { ...modelCopy, ...this.tabsModel };
      let response: any;
      this.isLoading = true;
      if (this.pendingFiles.length) {
        // @ts-ignore
        modelCopy.attachments = [...this.pendingFiles];
      } else {
        modelCopy.attachments = [];
      }

      if (this.edit) {
        response = await this.updateVendor({
          vendor: modelCopy,
          deletedFiles: this.deletedFiles
        });
      } else {
        response = await this.addVendor(modelCopy);
      }
      if (response) {
        this.isLoading = false;
        this.clearVendorToEdit();
      }
    }
  }

  public onFileChange(e: any) {
    const files = e.target.files as FileList;
    // getting file extension
    const fileExtension = files[0].name.match(/\.([^\.]+)$/)![1];
    // getting file size in "mega bytes" by dividing with 1000000
    const fileSize = files[0].size / 1000000;
    // only " alphanumeric,_ , - , ." characters are allowed in file name
    if (/([^a-zA-Z0-9_.\-])/.test(files[0].name)) {
      this.invalidFileName = true;
    } else {
      this.invalidFileName = false;
    }
    const invalidDocMsg: string | string[] = [];
    if (
      !store.getters["AuthModule/fileDocumentExtention"].includes(fileExtension)
    ) {
      invalidDocMsg.push(
        String(
          this.$t("security.invalid_file_extention", {
            fileExtention: store.getters["AuthModule/fileDocumentExtention"]
          })
        )
      );
    }
    if (fileSize > store.getters["AuthModule/fileUploadLimit"]) {
      invalidDocMsg.push(
        String(
          this.$t("security.invalid_file_size", {
            fileSize: store.getters["AuthModule/fileUploadLimit"]
          })
        )
      );
    }
    if (this.invalidFileName) {
      invalidDocMsg.push(String(this.$t("security.invalid_file_name")));
    }

    if (invalidDocMsg.length) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        invalidDocMsg,
        "error"
      );
    } else {
      // @ts-ignore
      this.pendingFiles = this.getFileNames(files);
    }
  }

  public removePendingFile(index: number) {
    this.pendingFiles.splice(index, 1);
  }

  protected async loadVendor() {
    let auxModel: any;
    this.isLoading = true;
    const id = this.$route.params.id || "";
    const emptyNote = {
      name: "new vendor note",
      title: "title",
      note: ""
    };
    const emptyContact = {
      name: "",
      phone: "",
      email: ""
    };

    if (id) {
      await this.getVendor(id);
      auxModel = cloneDeep(this.vendorToEdit);
    }

    if (auxModel) {
      if (!auxModel.notes.length) {
        this.tabsModel.notes = [{ ...emptyNote }];
      } else {
        this.tabsModel.notes = cloneDeep(auxModel.notes);
      }

      if (!auxModel.contacts.length) {
        this.tabsModel.contacts = [{ ...emptyContact }];
      } else {
        this.tabsModel.contacts = cloneDeep(auxModel.contacts);
      }

      this.model = cloneDeep({ ...this.model, ...auxModel, depend: "country" });
    }
    this.isLoading = false;
  }

  protected changePagination(pagination: TablePagination) {
    vendorService.setURI(this.vendorToEdit!.id);
    this.paginate({
      currentPage: pagination.currentPage,
      itemsPerPage: pagination.itemsPerPage
    });
    vendorService.setDefaultURI();
  }

  protected filter(header: TableHeader) {
    vendorService.sortQuery(header);
  }

  protected getFileNames(files: FileList) {
    const filesArr = [];
    if (files.length) {
      for (const file of files) {
        filesArr.push({ attachment: file });
      }
    }

    return filesArr;
  }
  protected created() {
    this.formFields.map(field => {
      if (this.$route.name === "vendor-view") {
        field.options!.disableCondition = () => true;
      } else {
        field.options!.disableCondition = () => false;
      }
    });
  }
  protected async mounted() {
    if (this.$route.name === "vendor-view") {
      this.hasModifyPermission = true;
    }
    this.setPageNav({
      title: "vendors.view_title",
      isLoading: () => this.isLoading,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            id: "btn_save",
            action: this.save,
            vuetifyProps: () => ({
              loading: this.isLoading,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            id: "btn_cancel",
            action: this.cancel,
            vuetifyProps: () => ({
              fab: true,
              small: true,
              disabled: this.isLoading
            })
          }
        ]
      }
    });
    this.loadVendor();
    this.$changes.watch(
      pusherEvents.vendorTouched,
      this.loadVendor,
      (data: PusherNotification) => {
        return !!data.message.find(n => n.item_id === +this.$route.params.id);
      }
    );

    const unwatch = this.$watch("activeTab", value => {
      if (value === 2 && this.$route.params.id) {
        this.loadVendorFiles(this.$route.params.id);
        unwatch();
      }
    });
  }
}
