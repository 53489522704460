export interface Vendor {
  account_id: number;
  address1: string;
  address2: string;
  country: number;
  city: number;
  contact: string;
  contacts?: Array<{ name: string; phone: string; email: string }>;
  created_at: string;
  created_by: string | null;
  deleted_at: string | null;
  email: string | null;
  id: number;
  documents?: VendorDocuments;
  is_active: boolean;
  license: string;
  name: string;
  notes?: Array<{ name: string; title: string; note: string }>;
  phone1: string | null;
  phone2: string | null;
  state: number;
  type: null;
  ubi: null;
  updated_at: string | null;
  updated_by: string | null;
  website: string | null;
  zip: string | null;
  attachments?: any[];
  _method?: string;
  pivot?: {
    id: number;
  };
}

export interface SharedVendor {
  account_id: number;
  address1: string;
  address2: string;
  country: number;
  city: number;
  contact: string;
  contacts?: Array<{ name: string; phone: string; email: string }>;
  created_at: string;
  created_by: string | null;
  deleted_at: string | null;
  email: string | null;
  id: string;
  is_active: boolean;
  license: string;
  name: string;
  notes?: Array<{ name: string; title: string; note: string }>;
  phone1: string | null;
  phone2: string | null;
  state: number;
  type: null;
  ubi: null;
  updated_at: string | null;
  updated_by: string | null;
  website: string | null;
  zip: string | null;
  attachments?: any[];
  _method?: string;
  pivot?: {
    id: number;
  };
}

export interface SharedVendor {
  account_id: number;
  address1: string;
  address2: string;
  country: number;
  city: number;
  contact: string;
  contacts?: Array<{ name: string; phone: string; email: string }>;
  created_at: string;
  created_by: string | null;
  deleted_at: string | null;
  email: string | null;
  id: string;
  is_active: boolean;
  license: string;
  name: string;
  notes?: Array<{ name: string; title: string; note: string }>;
  phone1: string | null;
  phone2: string | null;
  state: number;
  type: null;
  ubi: null;
  updated_at: string | null;
  updated_by: string | null;
  website: string | null;
  zip: string | null;
  attachments?: any[];
  _method?: string;
  pivot?: {
    id: number;
  };
}

export const vendorModel = {
  name: "",
  type: "",
  license: "",
  website: "",
  phone1: "",
  address1: "",
  city: "",
  state: "",
  country: "",
  contacts: [],
  phone2: "",
  email: "",
  notes: [],
  zip: "",
  attachments: [],
  is_active: true
};

export interface VendorDocuments {
  attachment_content_type: string;
  attachment_file_name: string;
  attachment_url: string;
  comments: string | null;
  created_at: string;
  created_by: VendorDocumentUser;
  avatar_original_url: string;
  id: number;
  updated_at: string;
  user?: string;
}

interface VendorDocumentUser {
  avatar_original_url: string;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
}
